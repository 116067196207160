.tenants-main-container {
    padding: 15px 2px 15px 15px;
    display: flex;
    flex-direction: column;
}

.header-text {
    font-weight: bold;
}

.tenant-container {
    display: flex;
    flex-direction: column;
}

.input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.user-label-text {
    width: 35%;
}

.user-radio-text {
    width: 28%;
}

.button-container {
    margin-left: auto;
}

.tenant-list-header {
    display: flex;
}

.icon-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    width: 25%;
}

.select-organization-user {
    width: 200px;
    margin-left: -36px;
}

.admin-radio {
    margin-right: 10px;
}

.input-radio {
    margin-right: 4px;
}