.tenants-main-container {
    padding: 15px;
}

.header-text {
    font-weight: bold;
}

.tenant-container {
    display: flex;
    flex-direction: column;
}

.config-input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.config-modal-label-text {
    width: 65%;
}

.config-select {
    width: 90%;
    padding: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
}

.button-container {
    margin-left: auto;
}

.tenant-list-header {
    display: flex;
}

.cloud-name-select {
    width: 90%;
}

.eye-icon {
    margin-top: 10px;
    margin-left: 5px;
}

.config-new-select-azure {
    width: 100%;
    margin-left: -10px;
}

.modal-text-overlap {
    word-wrap: break-word;
}