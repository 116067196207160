/* COLOR PALLET */

.GS-80 {
  color: var(--zui-color-gs-80);
}

/* CSS FLOAT */

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* CSS TEXT ALIGNMENT */

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

/* CSS POSITION */

.position-relative {
  position: relative;
}

/* CSS MARGIN */

.margin-right-100 {
  margin-right: 100px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-0 {
  margin: 0;
}

/* CSS CONTENT ALIGNMENT */

.flex-center {
  display: flex;
  justify-content: center;
}

/* CSS DISPLAY */

.display-flex {
  display: flex;
}

/* CSS CURSOR */

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

label {
  margin: 0px;
}
.toast-header-new {
  background: green !important;
  color: white !important;
}

.toast-container {
  position: absolute;
  bottom: 25px;
  right: 10px;
  width: 40%;
  z-index: 3000;
}