.tenants-main-container {
    padding: 15px;
}

.header-text {
    font-weight: bold;
}

.tenant-container {
    display: flex;
    flex-direction: column;
}

.config-input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.config-label-text {
    width: 45%;
}

.config-select {
    width: 90%;
    padding: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
}

.button-container {
    margin-left: auto;
}

.tenant-list-header {
    display: flex;
}

.import-files-button-container {
    width: 100%;
    display: flex;
}

.files-input {
    width: 70% !important;
}

.import-files-label-text {
    width: 17%;
    margin-right: 10px;
}

.import-files-input-container {
    width: 85%;
}

.reports-date-container {
    display: flex;
    flex-direction: row;
    padding: 15px;
}

.reports-tile-container {
    padding: 15px;
}

.drop-down {
    display: flex;
    flex-direction: row;
    width: 250px;
}

.select {
    width: 150px;
}