.tenants-main-container {
    padding: 15px;
}

.header-text {
    font-weight: bold;
}

.tenant-container {
    display: flex;
    flex-direction: column;
}

.config-input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
}

.config-label-text {
    width: 45%;
}

.config-select {
    width: 90%;
    padding: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
}

.button-container {
    margin-left: auto;
}

.tenant-list-header {
    display: flex;
}

.import-files-button-container {
    width: 100%;
    display: flex;
}

.files-input {
    width: 70% !important;
}

.import-files-destination-label-text {
    width: 17%;
    margin-left: 8px;
}

.import-files-input-container {
    width: 100%;
    margin-bottom: 45px;
    display: flex;
}

.import-files-select {
    width: 70%;
}

input[type='file'] {
    color: rgba(0, 0, 0, 0)
}

.file-input {
    width: 13%;
    margin-top: 10px;
}

.file-not-supported {
    color: red;
    text-align: center;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    background: #0050f2;
}

.destination-drop-down {
    width: 72%;
}

.custom-file-upload-disabled {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    background: #0050f2;
    opacity: 0.5;
}

.import-files-select-destination {
    width: 71%;
    height: 30px;
    margin-left: 5px;
}

.destination-select {
    width: 100%;
}