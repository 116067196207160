.job-status-list-info-header {
    background: white;
    color: black;
    width: 100%;
    table-layout: fixed;
    display: table;
}
.job-row {
    width: 100%;
    table-layout: fixed;
    display: table;
}

.job-status-list-table {
    width: 100%;
    color: #212529;
}

.job-status-list-table td, .job-status-list-table th {
    text-align: center;
    vertical-align: middle;
    padding: 0.25rem;
}

.job-status-list-table td {
    border: none;
}

.job-status-list-table thead th {
    vertical-align: middle;
    border-top: .5px solid var(--zui-color-gs-80);
    border-bottom: .5px solid var(--zui-color-gs-80);
    font-weight: 500;
    user-select: none;
    padding-bottom: 10px;
}

.job-status-list-table thead>tr {
    border-left: .5px solid var(--zui-color-gs-80);
    border-right: .5px solid var(--zui-color-gs-80);
}

.job-status-list-info-header {
    background: var(--zui-color-gs-10);
    color: var(--zui-color-gs-105);
}

.crd-table-row {
    cursor: pointer;
}

.crd-table-row:nth-of-type(odd) {
    background-color: var(--zui-color-gs-20);
}

/* Caret Icon to open report */

.crd-caret-icon-close {
    transform: rotate(45deg);
    transition: 1s ease-in-out;
}

.crd-caret-icon-open {
    transform: rotate(135deg);
    transition: 1s ease-in-out;
}

.crd-table-caret-icon {
    width: 30px;
    min-width: 30px;
}

/* Clinical Review Dashboard table column size styling */

.crd-table-patient-id {
    max-width: 12vw;
}

.crd-table-patient-name {
    max-width: 23vw;
}

.crd-table-diagnosis, .crd-table-next-visit, .crd-table-last-treatment, .crd-table-reviewed {
    max-width: 10vw;
}

.crd-table-eyes-monitored {
    max-width: 12vw;
}

.crd-table-uses-past-four-weeks {
    max-width: 8vw;
}

.job-status-table-edit-icon-size {
    width: 55px;
    min-width: 44px;
    user-select: none;
}

.crd-table-edit-icon {
    margin: auto;
}
.ar-body-content {
    /* height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
.job-status-tbody {
    display: block;
    width: 100%;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
.job-body {
    overflow-y: auto;
    height: calc(68vh);
    display: block;
}