.job-status-main-container {
    padding: 15px 2px 15px 15px;
    display: flex;
    flex-direction: column;
}

.header-text {
    font-weight: bold;
}

.tenant-container {
    display: flex;
    flex-direction: column;
}

.input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.user-label-text {
    width: 35%;
}

.user-radio-text {
    width: 28%;
}

.button-container {
    margin-left: auto;
}

.tenant-list-header {
    display: flex;
}

.icon-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    width: 21%;
}

.select-organization {
    width: 200px;
}

.job-status-view {
    margin-right: 10px;
}

.job-name {
    width: 86%;
}

.file-status-select {
    margin-left: 55%;
}

.progres-bar {
    margin-top: 10px;
}

.donwload-file-button {
    width: auto;
    height: 33px;
}

.custom-download-button {
    padding: 4px 12px;
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    color: white;
    background: blue;
}

.job-status-text {
    margin-bottom: 4px;
}

.disable-button {
    background: #0050f2;
    opacity: 0.5;
}

.file-status-modal-tbody {
    display: block;
    height: auto;
    overflow-y: auto;
    /* Trigger vertical scroll    */
    overflow-x: hidden;
    height: 23vh;
    /* max-height: 150px; */
}

.download-failed {
    text-align: center;
    color: red;
}

.download-button {
    height: 34px;
    background: blue !important;
    padding-top: 4px !important;
}

.disable-download-action {
    opacity: 0.5;
    cursor: default;
}

.toast-header-job-status {
    background: red !important;
    color: white !important;
}

.loading-text {
    text-align: center;
}

.loading-job-text {
    margin-top: 10px;
    text-align: center;
}

.config-new-select-job {
    width: 200px;
}