.tenants-main-container {
    padding: 15px 2px 15px 15px;
    display: flex;
    flex-direction: column;
}
.header-text {
    font-weight: bold;
}
.tenant-container {
    display: flex;
    flex-direction: column;
}
.input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.organization-label-text {
    width: 35%;
}
.button-container {
    margin-left: auto;
}
.error-message {
    color: red;
    text-align: center;
}
.feature-name-select  {
    width: 90%;
    margin-left: 42px;
}
.feature-dropdown {
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;
}
.date-picker-content {
    max-width: 12vw !important;
}
.licence-feature-text {
    margin-top: 15px;
    margin-bottom: 15px;
}
.crd-table-diagnosis {
    max-width: 11vw !important;
}
.lic-no-data {
    margin-top: 3%;
    text-align: center;
} 
.licence-new-select {
    margin-top: 2px;
    margin-left: 20px;
    width: 88%;
}