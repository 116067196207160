/* Sort Icon */

.unselected-caret {
    opacity: 0.5;
}

.selected-caret {
    opacity: 1;
}

.header-selected {
    background-color: #f5f7fa;
}

.sub-title {
    display: flex;
    margin-top: -5px;
    font-size: 10px;
}

.circle {
    background: var(--zui-color-sc-r-100);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: white;
    margin-bottom: 100%;
}

.circle-text {
    font-size: 9px;
    font-weight: 700;
    margin-top: 10%;
}

.table-header {
    display: grid;
    grid-template-columns: fit-content(100%) fit-content(100%);
}