.pt-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
    margin-left: auto;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    width: fit-content;
    max-width: 100%;
}

.pt-div {
    display: "flex";
    justify-content: "center";
    align-items: "center";
}