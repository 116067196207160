
.header-container {
    display: flex;
    margin-right: 15px;
    align-items: center;
}
.email-radio-container {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}
.email-notification-title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
}