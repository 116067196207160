.page-layout {
    display: flex;
    flex-direction: column;
}
.side-menu-container {
    display: flex;
    flex-direction: row;
}
.right-container {
    width: 100%;
    border-top: 1px solid #ccc;
}