.login-main-container {
    padding: 15px;
    height: 100%;
}
.login-form {
    padding-left: 26%;
    padding-right: 26%;
    padding-top: 10%;
    background: #f1f1f1;
    padding: 5%;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    width: 48%;
}
.login-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.email {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.email-label {
    margin-right: 8px;
    margin-top: 4px;
    width: 100px;
}
.button-container {
    margin-left: auto;
}
input[type=email], input[type=password], input[type=text] {
    width: 90%;
    padding: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
}
.login-button {
    background-color: #141e8c;
    color: white;
    height: 30px;
    width: auto;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.login-input-new-container {
    text-align: center;
    margin-top: 10%;
}
.timeout-button {
    height: 34px;
    background: #828d9e !important;
    padding-top: 4px !important;
}