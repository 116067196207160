.body {
    position: relative;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.wrap {
    right: 0;
    width: 250px;
}


/* select starting stylings ------------------------------*/

.select {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    position: relative;
    width: 250px;
}

.select-text {
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 250px;
    padding: 0px 10px 10px 0;
    font-size: 15px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


/* Remove focus */

.select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


/* active state */

.select-text:focus~.select-label,
.select-text:valid~.select-label {
    color: 1px solid rgba(0, 0, 0, 0.12);
    top: -20px;
    transition: 0.2s ease all;
    font-size: 14px;
}


/* BOTTOM BARS ================================= */

.select-bar {
    position: relative;
    display: block;
    width: 250px;
}

.select-bar:before,
.select-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: 1px solid rgba(0, 0, 0, 0.12);
    transition: 0.2s ease all;
}

.select-bar:before {
    left: 50%;
}

.select-bar:after {
    right: 50%;
}


/* active state */

.select-text:focus~.select-bar:before,
.select-text:focus~.select-bar:after {
    width: 50%;
}


/* HIGHLIGHTER ================================== */

.select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}